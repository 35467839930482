import { getDataFromCMS } from '@/utils/httpClient';
import { Topbanner, TopbannerConverter } from '@/models/VersionLoad.model';

export const getTopBannerData = async ({
  lang,
}: {
  lang?: string;
}): Promise<Topbanner | undefined> => {
  const [getDataCms] = await Promise.all([
    getDataFromCMS({ path: '/api/versionload', lang }),
  ]);
  const cmsData =
    getDataCms.status == 200
      ? getDataCms.data
        ? getDataCms.data
        : null
      : null;
  let response: Topbanner | undefined = cmsData?.topbanner;
  if (cmsData?.topbanner) {
    try {
      response = TopbannerConverter.fromJson(cmsData?.topbanner);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
