import { Banner } from '@/models/HomePage.model';
import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import Image from 'next/image';
import AppLink from '../Layout/handler/AppLink';

interface Props {
  promotionbanners?: Banner[][];
  name?: string;
}

export default function PromotionBanner({ promotionbanners, name }: Props) {
  const ratioDesktop = 85 / 8;
  const ratioMobile = 75 / 7;

  return (
    <>
      {promotionbanners ? (
        promotionbanners &&
        name &&
        promotionbanners
          .flat()
          .filter((item) => item.topic === name)
          .map((item, i) => (
            <>
              <div className="my-1">
                <Grid key={`col-${i}`} item xs={12} lg={12}>
                  <AppLink href={item.url}>
                    <Image
                      src={item.img}
                      alt={item.topic}
                      width={1360}
                      height={128}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </AppLink>
                </Grid>
              </div>
            </>
          ))
      ) : (
        <>
          <div className="my-1">
            <div
              className="hidden md:block"
              style={{
                height: `${100 / ratioDesktop}vw`,
                maxHeight: 1360 / ratioDesktop,
              }}
            >
              <Skeleton
                className="hidden md:block"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ maxHeight: 1360 / ratioDesktop }}
              />
            </div>
            <div
              className="block md:hidden"
              style={{
                height: `${100 / ratioMobile}vw`,
                maxHeight: 1360 / ratioMobile,
              }}
            >
              <Skeleton
                className="block md:hidden"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ maxHeight: 1360 / ratioMobile }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
