import { Banner } from '@/models/HomePage.model';
import { customerIdSelector } from '@/store/slices/loginSlice';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';

interface Props {
  banner: Banner;
  sectionName: string;
  position: number; //ต้อง +1 มากจาก index ของ loop เสมอ ถ้าไม่ได้มาจาก loop ใส่ 1 ใส่ไปเลย
  width: number;
  height: number;
  loading?: 'lazy' | 'eager' | undefined;
  style?: React.CSSProperties;
}

export default function PromotionImageImpression({
  sectionName,
  banner,
  position,
  width,
  height,
  loading,
  style,
}: Props) {
  const router = useRouter();
  const customerId = useSelector(customerIdSelector);
  const promotionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const promotion = promotionRef.current;
    if (!promotion || !(window as any).dataLayer || customerId === undefined)
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        (window as any).dataLayer.push({ ecommerce: null });
        (window as any).dataLayer.push({
          event: 'promotionView',
          userId: customerId,
          ecommerce: {
            promoView: {
              promotions: [
                {
                  name: banner.topic,
                  position: `${sectionName}_${position}`,
                },
              ],
            },
          },
        });

        observer.disconnect();
      }
    });

    observer.observe(promotion);

    return () => {
      if (promotion && observer) {
        observer.unobserve(promotion);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleBannerClick = () => {
    if (banner.url) {
      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({ ecommerce: null });
        (window as any).dataLayer.push({
          event: 'promotionClick',
          userId: customerId,
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  name: banner.topic,
                  position: `${sectionName}_${position}`,
                },
              ],
            },
          },
        });
      }
      if (banner.ahref) {
        window.open(`/${router.locale}/${banner.url}`, '_blank');
      } else {
        router.push(`/${router.locale}/${banner.url}`);
      }
    }
  };

  return (
    <div
      onClick={handleBannerClick}
      ref={promotionRef}
      className={`${banner.url ? 'cursor-pointer' : ''}`}
    >
      {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE === '1' ? (
        <Image
          src={banner.img}
          alt={banner.topic}
          width={width}
          height={height}
          loading={loading}
          style={style}
        />
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={banner.img}
          alt={banner.topic}
          width={width}
          height={height}
          loading={loading}
          style={style}
        />
      )}
    </div>
  );
}
