import React, { useRef } from 'react';
import Slider from 'react-slick';
import heroBannerClass from '@/styles/herobanner.module.css';
import { Box, Grid, Skeleton } from '@mui/material';
import Image from 'next/image';
import AppLink from '../Layout/handler/AppLink';
import { Banner } from '@/models/HomePage.model';

interface Props {
  promotionbannersslide?: Banner[];
}

export default function PromotionBannerSlide({ promotionbannersslide }: Props) {
  const ratioDesktop = 85 / 8;
  const ratioMobile = 75 / 7;
  const sliderRef = useRef<Slider>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const settings = promotionbannersslide
    ? {
        dots: false,
        infinite: promotionbannersslide.length > 3 ? true : false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonPrevious}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={previous}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-left"></i>
              </Box>
            </Box>
          </>
        ),
        nextArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonNext}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                right: '-1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={next}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-right"></i>
              </Box>
            </Box>
          </>
        ),
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      }
    : null;
  return (
    <>
      {promotionbannersslide ? (
        promotionbannersslide &&
        promotionbannersslide.length > 0 && (
          <div className="my-1">
            {/* <HeroBanner
              name="promotionbannerslide"
              banners={promotionbannersslide ?? undefined}
            /> */}
            <Grid container position="relative">
              <Grid item xs={12}>
                <Slider ref={sliderRef} {...settings}>
                  {promotionbannersslide.map((e, i) => (
                    <React.Fragment key={`slider-${i}`}>
                      <div className="hidden md:inline">
                        <AppLink href={e.url}>
                          {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE ===
                          '1' ? (
                            <Image
                              src={e.img}
                              alt={e.topic}
                              width={450}
                              height={222}
                              className="px-1"
                            />
                          ) : (
                            // eslint-disable-next-line @next/next/no-img-element
                            <img
                              src={e.img}
                              alt={e.topic}
                              width={450}
                              height={222}
                              className="px-1"
                            />
                          )}
                        </AppLink>
                      </div>
                      <div className="block md:hidden">
                        <AppLink href={e.url}>
                          {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE ===
                          '1' ? (
                            <Image
                              src={e.imgMobile ?? ''}
                              alt={e.topic}
                              width={240}
                              height={100}
                              className="px-1"
                            />
                          ) : (
                            // eslint-disable-next-line @next/next/no-img-element
                            <img
                              src={e.imgMobile}
                              alt={e.topic}
                              width={240}
                              height={100}
                              className="px-1"
                            />
                          )}
                        </AppLink>
                      </div>
                    </React.Fragment>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </div>
        )
      ) : (
        <>
          <div className="my-1">
            <div
              className="hidden md:block"
              style={{
                height: `${100 / ratioDesktop}vw`,
                maxHeight: 1360 / ratioDesktop,
              }}
            >
              <Skeleton
                className="hidden md:block"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ maxHeight: 1360 / ratioDesktop }}
              />
            </div>
            <div
              className="block md:hidden"
              style={{
                height: `${100 / ratioMobile}vw`,
                maxHeight: 1360 / ratioMobile,
              }}
            >
              <Skeleton
                className="block md:hidden"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ maxHeight: 1360 / ratioMobile }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
