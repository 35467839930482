import { Banner } from '@/models/HomePage.model';
import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import PromotionImageImpression from '../common/PromotionImageImpression';

interface Props {
  promotionevents?: Banner[];
}

export default function PromotionEvent({ promotionevents }: Props) {
  const ratioDesktop = 688 / 609;
  const ratioMobile = 86 / 77;
  return (
    <>
      {promotionevents ? (
        <div className="my-1">
          <Grid
            container
            spacing={2}
            className="px-1 md:px-5"
            columns={{ xs: 15 }}
          >
            {promotionevents?.map((promotionevent, i) => (
              <Grid key={i} item xs={3}>
                <PromotionImageImpression
                  sectionName="promotionevent" // ถ้ามีหัวข้อจาก CMS ก็เอามาใส่
                  position={i + 1}
                  banner={promotionevent}
                  width={246}
                  height={288}
                  loading="lazy"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <div className="my-1">
          <div>
            <div
              className="hidden md:block"
              style={{
                height: `${100 / ratioDesktop}vw`,
                maxHeight: 1360 / ratioDesktop,
              }}
            >
              <Skeleton
                className="hidden md:block"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ maxHeight: 1360 / ratioDesktop }}
              />
            </div>
            <div
              className="block md:hidden"
              style={{
                height: `${100 / ratioMobile}vw`,
                maxHeight: 1360 / ratioMobile,
              }}
            >
              <Skeleton
                className="block md:hidden"
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ maxHeight: 1360 / ratioMobile }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
