import { CMSCategoryPageModel } from '@/models/CMS.model';
import React, { useEffect, useState } from 'react';
import { useTranslation as translation } from 'next-i18next';
import { getHomepageBlock } from '@/services/server/homePageService';
import CmsWrapper from '../common/CmsWrapper';

export default function HomePageCMSBlock({ name }: { name: string }) {
  // Call API to get homepage data
  const { i18n } = translation();
  const [cmsData, setCmsData] = useState<CMSCategoryPageModel>();

  useEffect(() => {
    getHomepageBlock({ lang: i18n.language, name }).then((res) => {
      setCmsData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, i18n.language]);

  return (
    <>
      {cmsData && cmsData.contents && cmsData.contents.length > 0 && (
        <CmsWrapper contents={cmsData.contents}>
          <></>
        </CmsWrapper>
      )}
    </>
  );
}
