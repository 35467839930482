import { modelValue } from '@/utils/modelValue';

export interface VersionLoadModel {
  topbanner?: Topbanner;
  subcatcolor?: string;
  categorysort?: Categorysort;
}

export class VersionLoadModelConverter {
  public static fromJson(json: any): VersionLoadModel {
    const model: VersionLoadModel = {
      topbanner: json['topbanner']
        ? TopbannerConverter.fromJson(json['topbanner'])
        : undefined,
      subcatcolor: json['subcatcolor'] ? json['subcatcolor'] : undefined,
      categorysort:
        json['categorysort'] &&
        CategorysortConverter.fromJson(json['categorysort']),
    };
    return modelValue(model);
  }
}

export interface Categorysort {
  data?: any[];
  version?: number;
}

export class CategorysortConverter {
  public static fromJson(json: any): Categorysort {
    const model: Categorysort = {
      data: json['data'],
      version: json['version'],
    };
    return modelValue(model);
  }
}

export interface Topbanner {
  img?: string;
  imgMobile?: string;
  url?: string;
  color?: string;
  version?: number;
  ahref?: boolean;
}

export class TopbannerConverter {
  public static fromJson(json: any): Topbanner {
    const model: Topbanner = {
      img: json['img'] ? json['img'] : undefined,
      imgMobile: json['img_mobile'] ? json['img_mobile'] : undefined,
      url: json['url'] ? json['url'] : undefined,
      color: json['color'] ? json['color'] : undefined,
      version: json['version'] ? json['version'] : undefined,
      ahref: json['ahref'] ? json['ahref'] : undefined,
    };
    return modelValue(model);
  }
}
