import { Brand } from '@/models/HomePage.model';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import heroBannerClass from '@/styles/herobanner.module.css';
import { Box, Grid } from '@mui/material';
import Image from 'next/image';
import AppLink from '../Layout/handler/AppLink';
import { useTranslation as translation } from 'next-i18next';

export default function ShopByBrand({ brands }: { brands?: Brand[] }) {
  const { t } = translation();
  const sliderRef = useRef<Slider>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const settings = brands
    ? {
        dots: false,
        infinite: brands.length > 5 ? true : false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: true,
        prevArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonPrevious}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={previous}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-left"></i>
              </Box>
            </Box>
          </>
        ),
        nextArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonNext}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                right: '-1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={next}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-right"></i>
              </Box>
            </Box>
          </>
        ),
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
        ],
      }
    : null;
  return (
    <>
      {brands ? (
        <div className="my-1 px-2">
          <div className="flex items-center">
            <div
              className="h-px bg-black lg:ml-60"
              style={{ flexGrow: 1 }}
            ></div>
            <div className="px-2 text-primary">{t('home:shopbybrand')}</div>
            <div
              className="h-px bg-black lg:mr-60"
              style={{ flexGrow: 1 }}
            ></div>
            <div className="ml-auto underline text-sm pl-2">
              <AppLink href="/brand">{t('common:seeall')}</AppLink>
            </div>
          </div>
          <Grid container position="relative">
            <Grid item xs={12}>
              <Slider ref={sliderRef} {...settings}>
                {brands.map((e, i) => (
                  <React.Fragment key={`slider-${i}`}>
                    <AppLink href={`/brand/${e.brand}`}>
                      {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE === '1' ? (
                        <Image
                          src={e.img}
                          alt={e.brand}
                          width={240}
                          height={100}
                          className="px-1"
                        />
                      ) : (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                          src={e.img}
                          alt={e.brand}
                          width={240}
                          height={100}
                          className="px-1"
                        />
                      )}
                    </AppLink>
                  </React.Fragment>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
