/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react';

import { useTranslation as translation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getHomePageData } from '@/services/server/homePageService';
import { CMSHomePageModel } from '@/models/HomePage.model';
import HeroBanner from '@/components/homepage/HeroMenu/HeroBanner';
import useSWR from 'swr';
import { getTopBannerData } from '@/services/client/topBannerService';
import TopBannerContext from '@/contexts/TopBannerContext';
import PromotionEvent from '@/components/homepage/PromotionEvent';
import ShopByBrand from '@/components/homepage/ShopByBrand';
import FlashSale from '@/components/homepage/FlashSale';
import Head from 'next/head';
import { setCacheServerSideProps } from '@/utils/serverSidePropsInterceptor';
import { GetServerSidePropsContext } from 'next';
import AwPopup from '@/components/homepage/AwPopup';
import HomePageCMSBlock from '@/components/homepage/HomePageCMSBlock';
import ArticleList from '@/components/homepage/ArticleList';
import PromotionBanner from '@/components/homepage/PromotionBanner';
import PromotionBannerSlide from '@/components/homepage/PromotionBannerSlide';
import { useRouter } from 'next/router';
import { fetchTranslationsFromGCS } from '@/utils/fetchTranslationsFromGCS';
// import InsiderPage from '@/components/insider/InsiderPage';
import ShopByRoom from '@/components/homepage/ShopByRoom';

export default function index() {
  const { i18n } = translation();
  const { setTopBanner } = useContext(TopBannerContext);
  const [fullUrl, setFullUrl] = useState('');
  const router = useRouter();
  // Call API to get homepage data
  const {
    data,
    mutate,
    error,
    isLoading,
  }: {
    data?: CMSHomePageModel;
    mutate?: any;
    error?: any;
    isLoading?: boolean;
  } = useSWR('cmsHomepage', () =>
    getHomePageData({ lang: i18n.language }).then((res) => res),
  );

  // Call API to get top banner data
  const {
    mutate: mutateTopBanner,
  }: {
    mutate?: any;
  } = useSWR('cmsVersionLoad', () =>
    getTopBannerData({ lang: i18n.language }).then((res) => {
      if (res) {
        setTopBanner(res);
      }
    }),
  );

  useEffect(() => {
    mutate?.();
    mutateTopBanner?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const org =
      typeof window !== 'undefined'
        ? decodeURIComponent(window.location.origin)
        : '';
    setFullUrl(`${org}`);
  }, [router]);

  return (
    <>
      <Head>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="อัพเดทเทรนด์ เครื่องใช้ไฟฟ้า, เครื่องซักผ้า, แอร์, ตู้เย็น, มือถือ, แท็บเล็ต, กล้อง, คอมพิวเตอร์, gadget และ สินค้าไอทีอื่น ๆ อีกมากมาย พบกับโปรเด็ดทุกวัน Deal of the day, โปรเด็ดทุกสัปดาห์ Weekly Special, และ โปรใหญ่ประจำเดือน Big Campaign พร้อมรับส่วนลดอื่น ๆ ที่ร่วมโปร ฯ เช่น Coupon code, ผ่อน 0% ตั้งแต่ 4-10 เดือน."
        />
        <meta
          data-react-helmet="true"
          name="description"
          content="อัพเดทเทรนด์ เครื่องใช้ไฟฟ้า, เครื่องซักผ้า, แอร์, ตู้เย็น, มือถือ, แท็บเล็ต, กล้อง, คอมพิวเตอร์, gadget และ สินค้าไอทีอื่น ๆ อีกมากมาย พบกับโปรเด็ดทุกวัน Deal of the day, โปรเด็ดทุกสัปดาห์ Weekly Special, และ โปรใหญ่ประจำเดือน Big Campaign พร้อมรับส่วนลดอื่น ๆ ที่ร่วมโปร ฯ เช่น Coupon code, ผ่อน 0% ตั้งแต่ 4-10 เดือน."
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content="เครื่องใช้ไฟฟ้า, เครื่องซักผ้า, แอร์, ตู้เย็น, มือถือ, แท็บเล็ต, กล้อง, คอมพิวเตอร์, โดรน, gadget"
        />
        <meta
          data-react-helmet="true"
          name="og:keywords"
          content="เครื่องใช้ไฟฟ้า, เครื่องซักผ้า, แอร์, ตู้เย็น, มือถือ, แท็บเล็ต, กล้อง, คอมพิวเตอร์, โดรน, gadget"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              name: 'Power Buy',
              url: 'https://www.powerbuy.co.th/th',
              logo: `${fullUrl}/images/svg/logo.svg`,
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  telephone: '(+66)1324',
                  contactType: 'customer service',
                  availableLanguage: ['Thai', ' English'],
                  contactOption: [],
                },
              ],
              sameAs: [
                'https://www.facebook.com/Powerbuythailand',
                'https://www.instagram.com/powerbuy/',
                'https://twitter.com/Power_Buy',
                'https://www.youtube.com/PowerbuyTV',
                'https://page.line.me/powerbuy',
              ],
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'WebSite',
              name: 'Power Buy',
              url: 'https://www.powerbuy.co.th/th',
              potentialAction: {
                '@type': 'SearchAction',
                target:
                  'https://www.powerbuy.co.th/th/search/{search_term_string}',
                'query-input': 'required name=search_term_string',
              },
            }),
          }}
        />
      </Head>
      {/* <InsiderPage
        id="insiderHome"
        type="Home"
        isBreadcrumb={false}
      ></InsiderPage> */}
      {/* Awpopup */}
      <AwPopup awPopupData={data?.awPopups} />

      {/* HeroBanner */}
      <HeroBanner
        name="hero"
        banners={isLoading ? undefined : error ? [] : data?.data}
      />

      {/* PromotionBanner for event topic*/}
      <PromotionBanner
        name="eventheader"
        promotionbanners={isLoading ? undefined : data?.promotionbanners}
      />

      {/* PromotionEvent */}
      <PromotionEvent
        promotionevents={isLoading ? undefined : data?.promotionevents}
      />

      {/* Homepage_block1 */}
      <HomePageCMSBlock name="block1" />

      {/* FlashSale */}
      <FlashSale flashsale={isLoading ? undefined : data?.flashsale} />

      {/* Homepage_block2 */}
      <HomePageCMSBlock name="block2" />

      {/* Homepage_block3 */}
      <HomePageCMSBlock name="block3" />
      {/* Shop by room */}
      {data?.shopbyroom && <ShopByRoom shopByRoom={data?.shopbyroom} />}
      {/* PromotionBanner for guru topic*/}
      <PromotionBanner
        name="guruheader"
        promotionbanners={isLoading ? undefined : data?.promotionbanners}
      />

      <PromotionBannerSlide
        promotionbannersslide={
          isLoading ? undefined : data?.promotionbannersslide
        }
      />

      {/* Homepage_block4*/}
      <HomePageCMSBlock name="block4" />

      {/* Brand List */}
      <ShopByBrand
        brands={
          isLoading
            ? undefined
            : data?.brand && data?.brand && data.brand.length > 0
            ? data?.brand
            : []
        }
      />
      {/* Homepage_block5*/}
      <HomePageCMSBlock name="block5" />

      {/* article */}
      <ArticleList content={data?.articles} />
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  setCacheServerSideProps(context.res);
  const namespaces = ['common', 'home', 'productcard'];
  return process.env.NEXT_PUBLIC_NODE_ENV !== 'uat'
    ? {
        props: {
          ...(await serverSideTranslations(context.locale || 'th', namespaces)),
        },
      }
    : {
        props: {
          ...(await fetchTranslationsFromGCS(
            context.locale || 'th',
            namespaces,
          )),
        },
      };
}
