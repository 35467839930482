import { Position } from '@/models/HomePage.model';
import React, { useEffect, useRef, useState } from 'react';
import shopByRoom from '@/styles/shopByRoom.module.css';
import AppLink from '@/components/Layout/handler/AppLink';
import ProductImageBase64 from '@/components/common/ProductImageBase64';

interface Props {
  position: Position;
}

export default function ShopByRoomPin({ position }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return position?.roomData ? (
    <div
      ref={containerRef}
      className="absolute"
      style={{
        left: `${position.x}%`,
        top: `${position.y}%`,
      }}
    >
      <div
        className="absolute"
        style={{
          transform: 'translate(-50%, -50%)',
        }}
        onClick={() => setOpen(true)}
      >
        <div className="bg-transparent sm:bg-white w-5 h-5 rounded-full flex items-center justify-center select-none cursor-pointer z-10">
          <i className="bi bi-tag-fill leading-3 h-3 w-3 text-sm text-grayLight2"></i>
        </div>
      </div>
      {open && (
        <div
          className={`${shopByRoom.speechBubble} ${
            position.x <= 50 ? shopByRoom.bubbleRight : shopByRoom.bubbleLeft
          }`}
        >
          <AppLink href={`/product/${position.roomData?.slugname}`}>
            <div className="w-full flex flex-col items-center justify-center select-none">
              <ProductImageBase64
                width={80}
                base64={position.roomData?.base64}
                pimImage={position.roomData?.pimImage}
              />
              <div className="text-center leading-3 text-xs">
                {position.roomData?.name}
              </div>
              <div className="leading-4 text-redPrice">
                {position.roomData?.price}
              </div>
            </div>
          </AppLink>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}
