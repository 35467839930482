import { Shopbyroom, SkuRoomData } from '@/models/HomePage.model';
import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import AppLink from '../../Layout/handler/AppLink';
import Button from '@mui/material/Button';
import ShopByRoomPin from './Pin';
import useSWR from 'swr';
import { getShopByRoomSkuData } from '@/services/server/homePageService';

interface Props {
  shopByRoom?: Shopbyroom;
}

export default function ShopByRoom({ shopByRoom }: Props) {
  const { t, i18n } = useTranslation('home');

  const {
    data,
  }: {
    data?: SkuRoomData[];
  } = useSWR(['shopByRoomHomepage', i18n.language], () =>
    getShopByRoomSkuData({
      skus: shopByRoom?.position?.map((e) => e.sku) ?? [],
      lang: i18n.language,
    }).then((res) => res),
  );

  return shopByRoom && shopByRoom.img ? (
    <div className="mt-2">
      <div className="font-bold text-2xl pt-1 ms-2 text-center">
        {t('homedecorationideas')}
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <div className="relative">
              <Image
                src={`${shopByRoom.img}`}
                alt={shopByRoom.subject ?? 'Shop by room'}
                width={1268}
                height={539}
                className="w-full h-auto"
              />
              {shopByRoom.position?.map((e, i) => (
                <React.Fragment key={i}>
                  <ShopByRoomPin
                    position={{
                      ...e,
                      roomData: data?.find((x) => x.sku === e.sku),
                    }}
                  />
                </React.Fragment>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className="w-full h-full bg-white flex flex-col items-center justify-center py-2 px-1 lg:py-0">
              <div className="text-xl sm:text-2xl lg:text-3xl font-semibold leading-5 sm:leading-6 lg:leading-7">
                {shopByRoom.subject}
              </div>
              <div className="text-sm lg:text-base leading-4 lg:leading-4">
                {shopByRoom.description}
              </div>
              <div className="mt-1.5">
                <AppLink href={`/${shopByRoom.goto}`}>
                  <Button
                    size="small"
                    className="hover:opacity-90"
                    style={{
                      fontSize: '1rem',
                      backgroundColor: '#854BA2',
                      color: '#FFFFFF',
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    {t('common:viewallproducts')}
                  </Button>
                </AppLink>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : (
    <></>
  );
}
