import React, { useRef } from 'react';
import Image from 'next/image';
import { Article } from '@/models/HomePage.model';
import { Box, Grid, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import AppLink from '../Layout/handler/AppLink';
import heroBannerClass from '@/styles/herobanner.module.css';
import Slider from 'react-slick';

export default function ArticleList({
  content,
}: {
  content: Article[] | undefined;
}) {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const settings = content
    ? {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        prevArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonPrevious}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={previous}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-left"></i>
              </Box>
            </Box>
          </>
        ),
        nextArrow: (
          <>
            <Box
              className={`bg-white shadow-lg cursor-pointer ${heroBannerClass.buttonNext}`}
              sx={{
                position: 'absolute',
                zIndex: 9,
                right: '-1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={next}
            >
              <Box
                className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
                sx={{ lineHeight: '1rem' }}
              >
                <i className="bi bi-chevron-right"></i>
              </Box>
            </Box>
          </>
        ),
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
    : null;
  return (
    <div>
      {content && content.length > 0 && (
        <div className="relative my-2 p-2 bg-white">
          <div className="flex items-center lg:px-60">
            <div className="h-px bg-black" style={{ flexGrow: 2 }}></div>
            <div className="px-2 text-primary">{t('home:article')}</div>
            <div className="h-px bg-black" style={{ flexGrow: 2 }}></div>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Slider ref={sliderRef} {...settings}>
                {content.map((e, i) => (
                  <React.Fragment key={`slider-${i}`}>
                    <AppLink href={`/blog${e.slug}`}>
                      {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE === '1' ? (
                        <Image
                          src={e?.img ?? ''}
                          alt={e?.subject ?? ''}
                          width={380}
                          height={211}
                          className="px-1"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                          src={e.img}
                          alt={e.subject}
                          width={380}
                          height={211}
                          className="px-1"
                          style={{ width: '100%' }}
                        />
                      )}
                    </AppLink>
                    <div className="px-2">
                      <div className="font-bold pt-2">{e.subject}</div>
                      <div className="text-xs leading-none py-2">{e.title}</div>
                      <AppLink href={`/blog${e.slug}`}>
                        <Button
                          sx={{
                            borderRadius: 0,
                            fontSize: '0.75rem',
                            py: 1,
                            px: 2,
                            textDecoration: 'underline',
                            color: 'white',
                            backgroundColor: '#854BA2 !important',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {t('continuereading')}
                        </Button>
                      </AppLink>
                    </div>
                  </React.Fragment>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
